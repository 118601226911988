<script>
	export let src = '';
	export let alt = '';
	export let title = '';
	export let width;
	export let height;
	export let containerHeight = 'auto';
	export let resolutions = [320, 640, 1280];

	let srcset;

	$: if (src) {
		srcset = generateSrcset();
	}

	function generateSrcset() {
		return resolutions
			.map(
				(res) =>
					`${src}?width=${res}&height=${Math.round((height * res) / width)}&format=webp ${res}w`
			)
			.join(', ');
	}
</script>

<div class="image-container" style="height:{containerHeight}">
	<img {src} {alt} {title} {srcset} sizes="100vw" {width} {height} />
</div>

<style>
	.image-container {
		display: flex;
		overflow: hidden;
	}

	img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}
</style>
