<script>
	import Image from './Image.svelte';
	import { goto } from '$app/navigation';

	const menSrc =
		'https://cdn.shopify.com/s/files/1/0743/0248/8853/files/martin-slm_duffle-coat_mens_camel_01.jpg?v=1682789156';

	const womenSrc =
		'https://cdn.shopify.com/s/files/1/0743/0248/8853/files/Martina-British-Racing-Green-Ladies-Duffle-Coat-F-London-Tradition.jpg?v=1682789155';
</script>

<section>
	<div class="shop-cta-card">
		<button on:click={() => goto('/shop/men')}>
			<Image
				src={menSrc}
				alt="Men's Collection"
				title="Martin SLM men's duffle coat in camel colour"
				width="480"
				height="480"
			/>
			<div class="button-wrapper">
				<a class="button" href="/shop/men">Shop Men</a>
			</div>
		</button>
	</div>
	<div class="shop-cta-card">
		<button on:click={() => goto('/shop/women')}>
			<Image
				src={womenSrc}
				alt="Men's Collection"
				title="Martina Duffle womens' coat in British Racing Green colour"
				width="480"
				height="480"
			/>
			<div class="button-wrapper">
				<a class="button" href="/shop/women">Shop Women</a>
			</div>
		</button>
	</div>
</section>

<style lang="scss">
	section {
		display: flex;
		justify-content: space-evenly;
		background-color: #f7f7f7;
	}

	.shop-cta-card {
		display: flex;
		flex-direction: column;
		align-self: center;
		position: relative;
		border: 1px solid #eaeaea;
	}

	.button-wrapper {
		transition: transform 0.3s ease-in-out;
		display: flex;
		justify-content: center;
		margin-top: 1em;
		position: absolute;
		bottom: 2rem;
		width: 100%;
	}

	.button {
		background-color: #000;
		color: #fff;
		padding: 10px 20px;
		text-decoration: none;
		border: 1px solid white;
	}

	.shop-cta-card .button-wrapper,
	.shop-cta-card:hover .button-wrapper {
		transform: translate(-0, -10%);
	}

	// mobile devices
	@media (max-width: 767px) {
		section {
			flex-direction: column;
		}
	}
</style>
