<script>
	import { page } from '$app/stores';
	import ImageNew from './ImageNew.svelte';

	$: articles = $page.data.articles;

	const sizes = {
		'(min-width: 768px)': '400',
		'(max-width: 767px)': '400'
	};
</script>

<section>
	<div class="title">
		<h2>Stitching Stories</h2>
		<p>
			Explore the world of British manufacturing, unravel the stories behind our stitches, and
			immerse yourself in the artistry of London Tradition.
		</p>
	</div>

	{#if articles && articles.length > 0}
		<div class="article-gallery">
			{#each articles.slice(0, 3) as article}
				<a class="article" href="/blog/{article.slug}">
					<div class="article-thumb-container">
						<ImageNew
							class="article-thumb"
							src={article.featuredImage.src}
							alt={article.featuredImage.alt}
							imageWidth={article.featuredImage.width}
							imageHeight={article.featuredImage.height}
							{sizes}
						/>
					</div>
					<h3>{article.title}</h3>
				</a>
			{/each}
		</div>
	{/if}

	<div class="cta">
		<a href="/blog">Explore the Blog</a>
	</div>
</section>

<style>
	section {
		padding: 50px 0;
	}
	.title {
		text-align: center;

		color: #333;
	}

	.title h2 {
		margin: 0.3em;
	}

	.title p {
		color: #666;
		padding: 0 1rem;
	}

	.article-gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.article {
		flex: 0 1 calc(100% / 3 - 40px);
		margin: 20px;
		position: relative;
	}

	.article:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	.article-thumb-container {
		height: 200px;
		width: 100%;
		overflow: hidden;
	}

	:global(.article-thumb) {
		height: 100% !important;
		object-fit: cover;
	}

	.cta {
		text-align: center;
		margin-top: 2rem;
		display: flex;
		justify-content: center;
	}

	.cta a:hover {
		transform: scale(1.05);
	}

	.cta a {
		background-color: black;
		color: white;
		text-transform: none;
		padding: 10px 20px;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}

	.article h3 {
		position: absolute;
		bottom: 0;
		margin: 0;
		color: white;
		text-shadow: 0 0 15px black;
		width: 100%;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.5);
		padding: 10px;
		font-size: 0.9rem;
		text-transform: capitalize;
	}

	@media (max-width: 768px) {
		.article-gallery {
			flex-direction: column;
		}
	}
</style>
